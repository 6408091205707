require("./plugins/bootstrap.js");
require("./plugins/bootstrap-datepicker.js");
require("./plugins/slick.js");
require("./plugins/magnific-popup.js");

require("./modules/cbpicker.js");
require("./modules/forms.js");
require("./modules/reviews.js");
require("./modules/fastbooker.js");
require("./modules/mobile-nav.js");
require("./modules/sliders.js");

if ($('#maps').length){
require("./modules/location.js");
}


var el = document.querySelector('.js-cbpicker');

// CBPicker Config
var config = {
  el: el,
  startDateEl: document.querySelector('#arrival'),
  startDateLabel: document.querySelector('#arrivalLabel'),
  endDateEl: document.querySelector('#departure'),
  endDateLabel: document.querySelector('#departureLabel'),
  firstDayOfWeek: 1,
  orientation: 'horizontal',
};

if(el) {
  var cbpicker = new CBPicker(config);
};

$('.js-gallery').each(function() {
  $(this).magnificPopup({
      delegate: 'a',
      type: 'image',
      gallery: {
          enabled: true
      }
  });
});


