var btn = $('.js-fastbooker-btn');
btn.click(function (e) {
    e.preventDefault();
    var arrival = new Date($('#startDate').val());
    var departure = new Date($('#endDate').val());
    var discount;
    if($('#discount').val() == 'undefined'){
      discount = "";
    }else{
      discount = $('#discount').val();
    }
    var arrivalString = arrival.getFullYear() + "-" + ('0' + (arrival.getMonth() + 1)).slice(-2) + "-" + arrival.getDate();
    var departureString = departure.getFullYear() + "-" + ('0' + (departure.getMonth() + 1)).slice(-2) + "-" + departure.getDate();
    var href = "https://reservations.cubilis.eu/lodewijk-van-male-sint-kruis-brugge?Language=" + getCubilisLang() + '&DiscountCode=' + discount;
    if ((arrival !== 'NaN-NaN-NaN') && (departure !== 'NaN-NaN-NaN')) {
        href = "https://reservations.cubilis.eu/lodewijk-van-male-sint-kruis-brugge/Rooms/Select?Language=" + getCubilisLang() + "&Arrival=" + arrivalString + "&Departure=" + departureString + '&DiscountCode=' + discount;
      }
    window.open(href, '_blank');
});


function getCubilisLang()
{
  var lang = $('html').attr('lang');
    var cubilisLang = 'en-GB';
    if (lang === 'nl') {
        cubilisLang = 'nl-NL';
    } else if (lang === 'fr') {
        cubilisLang = 'fr-FR';
    } else if (lang === 'de') {
        cubilisLang = 'de-DE';
    }
    return cubilisLang;
}
