var gmaps = require('google-maps');
gmaps.KEY = 'AIzaSyDFq2vrcPCb8Tv_ULC50QYdeyufE9IiMWU';


(function () {

    gmaps.load(function (google) {
        var map;

        function initialize() {
            var glat = 51.207614;
            var glong = 3.285109;

            var mapOptions = {
                zoom: 15,
                scrollwheel: false,
                center: new google.maps.LatLng(glat, glong)
            };
            map = new google.maps.Map(document.getElementById('maps'), mapOptions);

            var marker = new google.maps.Marker({
                position: new google.maps.LatLng(glat, glong),
                map: map
            });
        }
        google.maps.event.addDomListener(window, 'load', initialize);
    });
})();
